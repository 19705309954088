import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0,4];

export const dictionary = {
		"/": [~5],
		"/(pre-auth)/2fa/enter-code": [21,[3]],
		"/(pre-auth)/2fa/request-code": [22,[3]],
		"/(pre-auth)/3fa": [23,[3]],
		"/error": [~32],
		"/(pre-auth)/integrations/saml/assert/[clientId]": [~25,[3]],
		"/(pre-auth)/integration/[type]": [~24,[3]],
		"/(pre-auth)/intratool": [~26,[3]],
		"/(pre-auth)/language": [27,[3]],
		"/(app)/lesson/answer": [6],
		"/(app)/lesson/content/pdf": [~7,[2]],
		"/(app)/lesson/content/slider": [~8],
		"/(app)/lesson/content/video": [~9],
		"/(app)/lesson/question": [~10],
		"/(app)/lesson/summary": [~11],
		"/(app)/personal-data/gender": [12],
		"/(app)/personal-data/pants": [13],
		"/(app)/personal-data/shirt": [14],
		"/(app)/personal-data/shoes": [15],
		"/(app)/personal-data/summary": [16],
		"/(app)/personal-data/vaccination": [17],
		"/(pre-auth)/privacy": [~28,[3]],
		"/(pre-auth)/staffbase": [~29,[3]],
		"/(app)/summary": [~18],
		"/test/icon-button": [33],
		"/test/icons": [34],
		"/test/text-input": [35],
		"/(app)/thank-you": [19],
		"/(pre-auth)/visitor/[clientId]/[selfServiceConfigId]": [~30,[3,4]],
		"/(pre-auth)/visitor/[clientId]/[selfServiceConfigId]/sign-up": [~31,[3,4]],
		"/(app)/welcome": [~20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';